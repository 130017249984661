<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div v-show="!isLoading" class="aankomende-roadtrip-wijzigen" :class="{ 'allowLunch': roadtrip.packedLunchPrice  > 0, 'allowDinner': roadtrip.dinnerPrice > 0 }">
            <div class="pa-2">
                <v-container>
                    <v-row no-gutters>
                        <v-col sm="8" offset-sm="2" class="d-flex">
                            <span class="display-1">Wijzig {{roadtrip.tripName}}</span>
                            <span class="body-1 tag-wrap">
                                <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                    :class="`${roadtrip.labelColor}`"
                                    text--white
                                    width="100"
                                    dense
                                    prominent
                                    text>{{roadtrip.label}}</v-alert>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col sm="8" offset-sm="2" class="">{{formatDayMonth(roadtrip.date)}}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="!orderDetails.isPaid">
                        <v-col sm="8" offset-sm="2" class="">
                            <LoggedInRegistration
                                :userId="userId"
                                :roadtrip="roadtrip"
                                :order="order"
                                :orderDetails="orderDetails"
                                :existingOrders="existingOrders"
                                :getFoodRestrictions="getFoodRestrictions"
                                :goto="goto"
                                :passengerOptions="passengerOptions"
                                :vehicleOptions="vehicleOptions" />
                        </v-col>
                    </v-row>
                    
                    </v-container>
                </div>
            <BestellingInfo :existingOrders="existingOrders" :orderDetails="orderDetails" :roadtrip="roadtrip" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { OrderDetail } from '@/models/OrderDetail';

import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

import LoadingIcon from '@/components/common/LoadingIcon';
import BestellingInfo from '@/components/kalender/bestelling-info';
import LoggedInRegistration from '@/components/roadtrip/signup/LoggedInRegistration';

export default {
    name: 'AankomendeRoadtripWijzigen',
    components: 
    {
        LoadingIcon,
        BestellingInfo,
        LoggedInRegistration
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, MenuHelperMixin, RoadtripMixin, MetaTagsMixin],
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        showCarOptions: false,
        txt_carSelected: "",
        isLoading: true,
        showPassengerList: false,
        orderDetails: new OrderDetail(),
        roadtrip: {
            date: "",
            title: "",
        },
        passengerOptions: [],
        vehicleOptions: [],
        roadtripUniqueName: "",
        productId: '',
        existingOrders: {
            hasMainOrder: false,
            orderDetails: []
        },
    }),
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.roadtripUniqueName = this.$route.params.trip;
                if (this.userId != '')
                {
                    this.getAccountInformation();
                }
            }
        },
        productId: {
            immediate: true,
            handler() {
                if (this.productId != '')
                {
                    this.getExistingOrderInformation();
                }
            }
        }
    },
    computed:
    {
        ...mapState('authorizedModule', ['userId']),
        ...mapState('account', ['status', 'user']),
        ...mapState('menuHelper', {
            fuelTypes: state => state.fuelTypes,
            productCategories: state => state.productCategories
        }),
    },
    beforeCreate() {
        this.$store.dispatch('menuHelper/getProductCategories');
        this.$store.dispatch('menuHelper/getFoodRestrictions');
        this.$store.dispatch('menuHelper/getVehicleOptions');
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted () {
        this.$store.commit('setFooterVisibility', false);
    },
    metaInfo() {
        return {
        title: this.roadtrip.tripName ? `| Wijzigen ${this.roadtrip.tripName}` : '| Aankomende Roadtrip Wijzigen',
        meta: [
            this.getKeywords(`Wijzigen ${this.roadtrip.tripName}`),
            this.getMetaDescription(`Aankomende Roadtrip Wijzigen - ${this.roadtrip.tripName}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Aankomende Roadtrip Wijzigen`, `Aankomende Roadtrip Wijzigen - ${this.roadtrip.tripName}`, ``, 'Aankomende Roadtrip Wijzigen'),
        ]
        };
    },
    methods:
    {
        order()
        {
            this.isLoading = true;

            var passengerOrderDetails = [];
            this.orderDetails.registeredPassengers.forEach(passenger => {
                    let order = this.getNewOrder(passenger);
                    if(order != null)
                    {
                        passengerOrderDetails.push(order);
                    }
                });

            // add driver order if has changes
            let driverOrder = this.getNewOrder(this.orderDetails.driver);
            if(driverOrder != null)
            {
                passengerOrderDetails.push(driverOrder);
            }

            var request = {
                accountId: this.userId,
                vehicleId: this.orderDetails.car.id != "" ? this.orderDetails.car.id : null,
                productId: this.roadtrip.productId,
                category: this.getProductCategoryId("RoadtripModificatie"),
                note: this.orderDetails.notes,
                orderDetails: passengerOrderDetails,
                voucherCode: this.orderDetails.voucherCode
            }

            this.$store.dispatch('storeModule/placeOrderWithExistingAccount', request)
                .then(data => {

                    if(data == "")
                    {
                        this.$store.commit('dialog/showDialog', `De wijziging is succesvol in ons systeem verwerkt.`);
                        this.isLoading = false; 
                    }
                    else
                    {
                        window.location = data;
                    }
            })
            .catch(error => { 
                this.$store.commit('dialog/showDialog', error);
                this.isLoading = false; 
            });
        },
        getNewOrder(person)
        {
            var mainOrder = this.existingOrders.orderDetails.find(x => x.personId == person.personId);
            var newRequest = {
                hasBreakfast: mainOrder != null && mainOrder.hasBreakfast ? false : person.hasBreakfast,
                hasPackedLunch: mainOrder != null && mainOrder.hasPackedLunch ? false : person.hasLunch,
                hasDinner: mainOrder != null && mainOrder.hasDinner ? false : person.hasDinner,
                hasHotel: mainOrder != null && mainOrder.hasHotel ? false : person.hasHotel,
                personId: person.personId
            }

            // new order detail can just have a passenger without add-ons
            if(!newRequest.hasBreakfast
            && !newRequest.hasPackedLunch
            && !newRequest.hasDinner
            && !newRequest.hasHotel
            && mainOrder != null)
            {
                return null;
            }
            else
            {
                return newRequest;
            }
            
        },
        driverhasMainOrderForLunch()
        {
            let driverLunchInMainOrder = false;
            if (this.existingOrders.hasMainOrder)
            {
                // check in the main order if driver ordered lunch
                let driver = this.existingOrders.orderDetails.find(x => x.personId == this.orderDetails.driver.personId);
                if(driver != null)
                {
                    driverLunchInMainOrder = driver.hasPackedLunch;
                }
            }

            return driverLunchInMainOrder;
        },
        getRoadtripInformation()
        {
            this.$store.dispatch('productModule/getPublicProductByName', this.roadtripUniqueName)
                .then(data => {
                this.productId = data.productId;
                this.roadtrip = this.mapRoadtripData(data);
            })
            .catch(() => { this.isLoading = false; });
        },
        getAccountInformation(){
            this.getPassengerOptions();
            this.getVehicleOptions();
            this.$store.dispatch('accountModule/getAccountOwnerBasePersoninfo', this.userId)
            .then((data) => {
                this.orderDetails.driver.firstname = data.firstname;
                this.orderDetails.driver.personId = data.personId;
                this.orderDetails.driver.allergies = data.foodRestrictions;

                this.getRoadtripInformation();
            })
            .catch(() => { this.isLoading = false; });
        },
        getExistingOrderInformation()
        {
            this.$store.dispatch('storeModule/getOrdersInformation',
            {
                accountId: this.userId,
                productId: this.productId
            })
            .then((data) => {
                this.existingOrders = data;

                // bind existing order information
                data.orderDetails.forEach(order => {
                    
                    if (order.personId == this.orderDetails.driver.personId)
                    {
                        this.setOrderAddOns(this.orderDetails.driver, order);
                    }
                    else
                    {
                        var passenger = this.passengerOptions.find(x => x.personId == order.personId);
                        if(passenger != null)
                        {
                            this.setOrderAddOns(passenger, order);
                            this.orderDetails.registeredPassengers.push(passenger);
                        }
                    }

                });

                this.$set(this.orderDetails, 'car', this.vehicleOptions.find(x => x.id == data.vehicleId));
                this.$set(this.orderDetails, 'notes', data.note);
                this.existingOrders.hasMainOrder = true;
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        setOrderAddOns(person, order)
        {
            this.$set(person, 'hasBreakfast', order.hasBreakfast);
            this.$set(person, 'hasLunch', order.hasPackedLunch);
            this.$set(person, 'hasDinner', order.hasDinner);
            this.$set(person, 'hasHotel', order.hasHotel);
        },
        getPassengerOptions()
        {
            this.$store.dispatch('accountModule/getCoRegisteredPersonsFromAccount', this.userId)
                .then(array => {
                    
                    array.forEach(person => {
                        person.name = `${person.firstname} ${person.lastName}`;
                        person.initials = this.getPersonInitials(person.firstname, person.lastName);

                        // make these properties reactive
                        this.$set(person, 'hasLunch', false);
                        this.$set(person, 'hasDinner', false);
                        this.$set(person, 'hasBreakfast', false);
                        this.$set(person, 'hasHotel', false);
                        this.passengerOptions.push(person);
                    });
                })
        },
        getVehicleOptions()
        {
            this.$store.dispatch('vehicleModule/getVehiclesByAccountId', this.userId)
            .then(array => {

                array.forEach(element => {
                    this.vehicleOptions.push(element);
                });

                // set first car option as driver car 
                if(this.vehicleOptions.length > 0)
                {
                    this.$set(this.orderDetails, 'car', this.vehicleOptions[0]);
                }
            })
        },
      
    }
}
</script>
<style>

</style>